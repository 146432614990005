import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import './Reminder.scss';
import { Post } from '../../Config/config';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';
import Loader from '../Loader/Loader';

function Reminder() {
    const op = useRef(null);
    const [selectedParties, setSelectedParties] = useState(null);
    const [parties, setParties] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");

    // Loader
    const [loader, setLoader] = useState(false);

    const fetchParties = async () => {
        if (!isDataLoaded) {
            try {
                const res = await Post('AllCombo/CmbParty', {
                    PartyId: 0,
                    Balance: true
                });

                if (res && res.status === 1) { 

                    const transformedData = res.UserData.map(party => ({
                        name: party.PartyName,
                        balance: party.OpeningBalance,
                        code: party.PartyId
                    }));
                    setParties(transformedData);
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error('Error fetching party data:', error);
            }
        }
    };

    const resetParties = () => {
        setSelectedParties(null);
    };

    const handlePartiesClick = () => {
        const selectedPartyIds = selectedParties ? selectedParties : [];
        setLoader(true)
        Post('Party/Reminder', {
            PartyIds: selectedPartyIds
        }).then((response) => {
            setLoader(false);
            if (response && response.status === 1) {
                op.current.hide();
                setSankbar(true);
                setSankbarText(response.message);
                setSankbarColor("success");
                setSelectedParties(null);
            } else {
                setSankbar(true);
                setSankbarText(response.message);
                setSankbarColor("error");
            }
        }).catch(error => {
            console.error('Error sending reminder:', error);
        });
    };

    const itemTemplate = (option) => {
        return (
            <div className="party_name">
                <span>{option.name}</span>
                <span>{option.balance}</span>
            </div>
        );
    };

    const handleIconClick = (e) => {
        fetchParties();
        op.current.toggle(e);
    };

    return (
        <div id='Reminder'>
            <Button type="button" icon="pi pi-send" onClick={handleIconClick} />
            <OverlayPanel ref={op} id='Remainder'>
                <div className='card'>
                    <h3>Party Reminder Message</h3>
                    <div className='contact'>
                        <MultiSelect
                            value={selectedParties}
                            onChange={(e) => setSelectedParties(e.value)}
                            options={parties}
                            optionLabel="name"
                            optionValue="code"
                            filter
                            placeholder="Select Parties"
                            maxSelectedLabels={3}
                            className="w-full md:w-20rem"
                            itemTemplate={itemTemplate}
                        />
                        <div className='button'>
                            <Button label='Clear' onClick={() => resetParties()} severity="secondary" raised />
                            <Button label='Confirm' onClick={handlePartiesClick} raised />
                        </div>
                    </div>
                </div>
            </OverlayPanel>

            {loader ? <Loader /> : ""}

            <SnackBarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={(value) => setSankbar(value)}
            />
        </div>
    );
}

export default Reminder;
