import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function KeyEventHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const keyMap = {
      S: '/addSaleInvoices',
      P: '/addPurchaseBills',
      R: '/addCreditNote',
      I: '/paymentin',
      O: '/paymentout',
      E: '/expensive/addExpensive',
      L: '/addDebitNote',
      F: '/saleaddorder',
      A: '/additems',
    };

    const handleKeyDown = (event) => {
      if (event.altKey) {
        const route = keyMap[event.key.toUpperCase()];
        if (route) {
          event.preventDefault();

          if (route === '/addSaleInvoices') {
            sessionStorage.setItem('F_code', 'SLN');
          } else if (route === '/addPurchaseBills') {
            sessionStorage.setItem('F_code', 'PRB');
          } else if (route === '/addCreditNote') {
            sessionStorage.setItem('F_code', 'SRT');
          } else if (route === '/paymentin') {
            sessionStorage.setItem('F_code', 'PIN');
          } else if (route === '/paymentout') {
            sessionStorage.setItem('F_code', 'POU');
          } else if (route === '/expensive/addExpensive') {
            sessionStorage.setItem('F_code', 'EXP');
          } else if (route === '/addDebitNote') {
            sessionStorage.setItem('F_code', 'PUR');
          } else if (route === '/saleaddorder') {
            sessionStorage.setItem('F_code', 'SOR');
          } else if (route === '/additems') {
            sessionStorage.setItem('F_code', 'ITM');
          }
          navigate(route);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  return null;
}

export default KeyEventHandler;
