import CryptoJS from 'crypto-js';

function parseDate(dateStr) {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day);
}

function formatDate(date) {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
};

const validatePassword = (password) => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const correctPassword = `GT${day}${month}`;

    if (password === correctPassword) {
        return { isValid: true, message: '' };
    } else {
        return { isValid: false, message: 'Incorrect password, please try again.' };
    }
};

const secretKey = process.env.REACT_APP_SECRET_KEY;

// const localStoragesetItem = (key, value) => {
//     try {
//         const secretKey = process.env.REACT_APP_SECRET_KEY;
//         const encryptedUserData = CryptoJS.AES.encrypt(value, secretKey).toString();
//         const encryptedKey = CryptoJS.AES.encrypt(key, secretKey).toString();

//         localStorage.setItem(encryptedKey, encryptedUserData);

//         // Log the encrypted key and value stored
//         console.log('Stored:', { encryptedKey, encryptedUserData });
//         console.log('Current localStorage:', localStorage);
//     } catch (error) {
//         console.error('Error storing data:', error);
//     }
// };

// const localStorageGetItem = (key) => {
//     try {
//         console.log('Key for retrieval:', key); // Log the raw key

//         const encryptedKey = CryptoJS.AES.encrypt(key, secretKey).toString();

//         console.log('Encrypted Key for Retrieval:', encryptedKey);

//         const encryptedUserData = JSON.parse(localStorage.getItem(encryptedKey));

//         // Log the key used for retrieval and retrieved data
//         console.log('Encrypted User Data:', encryptedUserData);

//         if (!encryptedUserData) {
//             throw new Error('No data found for the provided key.');
//         }

//         const decryptedData = CryptoJS.AES.decrypt(encryptedUserData, secretKey).toString(CryptoJS.enc.Utf8);
//         return decryptedData;
//     } catch (error) {
//         console.error('Error retrieving data:', error);
//         return null;
//     }
// };

const encrypt = (data) => {

    const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    return ciphertext;
};

const decrypt = (ciphertext) => { 

    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return originalData;
};

export const lkstoregset = (key, value) => {
    const encryptedKey = encrypt(key);
    const encryptedValue = encrypt(value);
    localStorage.setItem(encryptedKey, encryptedValue);
};

export const lkstoregget = (key) => {
    const encryptedKey = encrypt(key);
    const encryptedValue = localStorage.getItem(encryptedKey);
    if (encryptedValue) {
        return decrypt(encryptedValue);
    }
    return null;
};


export {
    parseDate,
    formatDate,
    validatePassword,
    // localStoragesetItem,
    // localStorageGetItem
}





